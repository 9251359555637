var ajaxValueComponent = Vue.extend({
    template: '<span>{{ value }}</span>',
    data: function () {
        return {
            value: this.loadingMessage
        }
    },
    props: {
        sourceUrl: {},
        loadingMessage: {default: 'Loading...'},
        valueFilter: {default:false}
    },
    methods: {
        fetchValue: function () {
            console.log('fetch: '+this.sourceUrl);
            this.$http.get(this.sourceUrl).then(
                function (response) {
                    var value = response.text();
                    if(this.valueFilter) {
                        value = this.$options.filters[this.valueFilter](value);
                    }
                    //Apply filter if set
                    console.log(this.valueFilter);
                    this.$set('value',value);
                }, function (response) {
                    console.log('error');
                    console.log(response);
                    this.$set('value','error');
                });
        }
    },
    ready: function () {
        this.fetchValue();
    }
});
Vue.component('ajax-value', ajaxValueComponent);