$(document).ready(function () {
    // Init Theme Core
    Core.init();
    //console.log($.fn.tooltip.Constructor.VERSION);
    //datetimepicker
    /*var dateTimePickerElement = '.datetimepicker';
     if ( $(dateTimePickerElement).length ) {
     $(dateTimePickerElement).datetimepicker();
     }*/
    $('.datatable').DataTable({
        "aoColumnDefs": [{
            'bSortable': false,
            'aTargets': [-1]
        }],
        "oLanguage": {
            "oPaginate": {
                "sPrevious": "",
                "sNext": ""
            }
        },
        "iDisplayLength": 25,
        "aLengthMenu": [
            [25, 50, 100, -1],
            [25, 50, 100, "All"]
        ],
        "sDom": '<"dt-panelmenu clearfix"lfr>t<"dt-panelfooter clearfix"ip>'
    });

    var dataTableAccount = $('.datatable-account').DataTable({
        "aoColumnDefs": [{
            'bSortable': false,
            'aTargets': [-1]
        }],
        "oLanguage": {
            "oPaginate": {
                "sPrevious": "",
                "sNext": ""
            }
        },
        "iDisplayLength": 25,
        "aLengthMenu": [
            [25, 50, 100, -1],
            [25, 50, 100, "All"]
        ],
        "sDom": '<"dt-panelmenu clearfix">t<"dt-panelfooter clearfix"ip>'
    });
    if (dataTableAccount) {
        $('.filter-account-table', dataTableAccount.tables()[0]).on('keyup change', function () {
            var colId = $(this).attr('data-colid');
            console.log(colId);
            dataTableAccount
                .column(colId)
                .search(this.value)
                .draw();
        });
    }


    // Add Placeholder text to datatables filter bar
    $('.dataTables_filter input').attr("placeholder", "Enter Terms...");
});

new Vue({
    el: '#main',
    data: {}
});

Vue.filter('float', function (value, precision) {
    value = parseFloat(value);
    return value.toFixed(precision)
});

Vue.filter('float1', function (value) {
    value = parseFloat(value);
    return value.toFixed(1);
});

Vue.filter('float2', function (value) {
    value = parseFloat(value);
    return value.toFixed(2);
});