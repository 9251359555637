var ajaxMonthValueBlockComponent = Vue.extend({
    template: '#vue-ajax-month-value-block',
    data: function () {
        return {
            valuePrimary: this.loadingMessage,
            valueSecondary: this.loadingMessage,
            valueChangePercentage: this.loadingMessage
        }
    },
    props: {
        sourceUrl: {},
        name: {},
        valuePrimarySource: {},
        valuePrimaryFilter: {},
        valueSecondarySource: {},
        valueSecondaryFilter: {},
        valueChangePercentageSource: {},
        loadingMessage: {default: 'Loading...'},
        valueFilter: {default:false}
    },
    methods: {
        fetchValue: function () {
            console.log('fetch: '+this.sourceUrl);
            this.$http.get(this.sourceUrl).then(
                function (response) {
                    var responseJson = response.json();
                    console.log(responseJson);
                    if (responseJson[this.valuePrimarySource]){
                        var valuePrimary = responseJson[this.valuePrimarySource];
                        if(this.valuePrimaryFilter) {
                            valuePrimary = this.$options.filters[this.valuePrimaryFilter](valuePrimary);
                        }
                        this.$set('valuePrimary',valuePrimary);
                    }
                    if (responseJson[this.valueSecondarySource]){
                        var valueSecondary = responseJson[this.valueSecondarySource];
                        if(this.valueSecondaryFilter) {
                            valueSecondary = this.$options.filters[this.valueSecondaryFilter](valuePrimary);
                        }
                        this.$set('valueSecondary',valueSecondary);
                    }
                    if (responseJson[this.valueChangePercentageSource]){
                        var valueChangePercentage = responseJson[this.valueChangePercentageSource];
                        this.$set('valueChangePercentage',valueChangePercentage);
                    }
                }, function (response) {
                    console.log('error');
                    console.log(response);
                });
        }
    },
    ready: function () {
        this.fetchValue();
    }
});
Vue.component('ajax-month-value-block', ajaxMonthValueBlockComponent);